[class*=' rbc-'],
[class^='rbc-'] {
  font-family: 'Inter', sans-serif !important;
}

.h1 {
  font-weight: 700;
  font-size  : 32px;
}

.is-required {
  color: var(--color-danger);
}

input.ant-input,
.ant-input-password,
textarea.ant-input {
  border-radius: 6px !important;
  border-color : var(--color-secondary) !important;
}

.ant-select.has-error .ant-select-selector {
  border-color: var(--color-danger) !important;
}

input.ant-input.has-error,
.ant-input-password.has-error,
textarea.ant-input.has-error {
  border-color: var(--color-danger) !important;
}

.error-message {
  color: var(--color-danger);
}

.loading-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  
}

.loading-wrapper .loading-animation{
  width: 6rem;
}


.ant-tooltip-inner {
  border-radius: 5px !important;
  font-family  : 'Inter', sans-serif;
  font-size    : 0.8em;
  display      : flex;
  align-items  : center;
  min-height   : 29px !important;
}

.ant-pagination {
  margin    : 0px !important;
  margin-top: 1rem !important;
}

.ant-pagination>li {
  margin-top : 0px !important;
  font-family: 'Inter', sans-serif;
}

.ant-pagination>li>a,
.ant-pagination>li>button {
  color: var(--color-secondary);
}

.ant-pagination>li,
.ant-pagination>li>button {
  border          : none !important;
  background-color: transparent !important;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a,
.ant-pagination-next button:hover,
.ant-pagination-prev button:hover,
.ant-pagination-item-active a {
  color: var(--color-active) !important;
}

.ant-select {
  width: 100% !important;
}

.ant-select-selector {
  border-radius: 5px !important;
  border       : 1px solid var(--color-stroke) !important;
}

.ant-checkbox-wrapper>span {
  color: var(--color-black);
}

.ant-picker-cell .ant-picker-cell-inner {
  text-align: center !important;
}

.container {
  max-width: 100% !important;
}

.content {
  margin-bottom: 0px !important;
  border-bottom: 1px solid var(--color-stroke) !important;
}

.breadcrumb-active {
  color: var(--color-active);
}

h1.page-title {
  font-weight  : 700;
  font-size    : 32px;
  color        : var(--color-active);
  margin-top   : 1em;
  margin-bottom: 1.5em;
}

.header-content {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  margin-bottom  : 1rem;
}

.button-primary {
  background-color: var(--primary-button);
  border          : none;
  padding         : 0.6rem 1rem;
  border-radius   : 5px;
  font-weight: 700;
  color           : #fff;
  cursor          : pointer;
}

.thead {
  background: var(--table-header-color) !important;
}

.table-date {
  background   : #E9EDF5 !important;
  border-radius: 5px !important;
  width        : 180px !important;
  text-align   : center;
}

.table-name {
  color      : var(--table-name-color);
  font-weight: 500;
  font-size  : 12px;
}

.table-organization {
  color      : var(--color-orange);
  font-weight: 500;
  font-size  : 12px;
}

.table-desc {
  color      : var(--table-desc-color);
  font-weight: 400;
  font-size  : 12px;
}

.button-primary>i {
  margin-right: 0.5rem;
}

.button-primary:hover {
  opacity: 0.8;
}

.header-right-content {
  display    : flex;
  align-items: flex-end;
}

.grid-icon {
  display: flex;
}


.icon-container {
  padding      : 1rem 1rem;
  padding-left : 0 !important;
  border-radius: 5px;
  display      : flex;
  align-items  : center;
  color        : var(--color-grey-menu) !important;
}

.icon-container-active {
  padding      : 1rem 1rem;
  padding-left : 0 !important;
  border-radius: 5px;
  display      : flex;
  align-items  : center;
  color        : var(--color-primary) !important;
}

.searchbar {
  margin-right : 0.5rem;
  border       : 1px solid var(--color-stroke);
  padding      : 0.4rem 1rem;
  border-radius: 5px;
  display      : flex;
  align-items  : center;
  color        : black !important;
}

.search-error {
  margin-top: 0.25rem;
  color     : var(--color-danger);
  font-size : small;
}

.searchbar>i {
  margin-right: 0.5rem;
  color       : var(--color-light-grey);
  font-size   : 1.2em;
}

.searchbar>input {
  border          : none;
  background-color: transparent;
}

.searchbar>input:focus {
  outline: none;
}

.button-secondary {
  background-color: #fff;
  /* border          : 1px solid var(--color-primary);
  border-radius   : 10px;
  color           : var(--color-primary);
  padding         : 0.5rem 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-stroke-dark);
  border-radius: 0.525rem;
  padding: 0.6rem 1rem;
  color: var(--color-text-black);
  font-weight: 700;
  cursor          : pointer;
  font-weight     : 700;
}


.button-primary-outline {
  background-color: #fff;
  border          : 1px solid var(--color-primary);
  border-radius   : 10px;
  color           : var(--color-primary);
  padding         : 0.6rem 2rem;
  display: flex;
  align-items: center;
  /* border: 1px solid var(--color-stroke-dark);
  border-radius: 0.525rem;
  padding: 0.5rem 1rem;
  color: var(--color-text-black); */
  font-weight: 700;
  cursor          : pointer;
  font-weight     : 700;
}

.button-primary-outline:hover {
  opacity: 0.8;
}

.button-primary-outline>i {
  margin-right: 0.5rem;
}


.button-secondary:hover {
  opacity: 0.8;
}

.button-secondary>i {
  margin-right: 0.5rem;
}


.button-danger {
  background-color: var(--color-danger);
  border          : 1px solid var(--color-grey-menu);
  border-radius   : 10px;
  color           : #fff;
  font-weight     : 700;
  padding         : 0.8rem 1rem;
  cursor          : pointer;
}

.button-danger:hover {
  opacity: 0.8;
}

.button-danger>i {
  margin-right: 0.5rem;
}


.button-danger-secondary {
  background-color: #fff;
  border          : 1px solid var(--color-grey-menu);
  border-radius   : 10px;
  color           : var(--color-danger);
  font-weight: 700;
  padding         : 0.8rem 1rem;
  cursor          : pointer;
}

.button-danger-secondary:hover {
  opacity: 0.8;
}

.button-danger-secondary>i {
  margin-right: 0.5rem;
}

.button-loading {
  opacity: 0.5;
  cursor : not-allowed;
}

.mt-2 {
  margin-top: 2rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-1\/5 {
  margin-right: .5em;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}



.custom-modal .ant-modal-content {
  padding         : 0px;
  background-color: #000;
  display         : flex;
  justify-content : center;
  align-items     : center;
  border-radius   : .5em;
}

.custom-modal .ant-modal-body {
  padding         : 0px;
  background-color: #000;
  width           : 100%;
  height          : 100%;
  border-radius   : .5em;
}

.custom-modal .ant-modal-body img {
  width     : 100%;
  height    : 350px;
  object-fit: contain;
}

.custom-modal .ant-modal-close {
  background-color: var(--color-bg-gray);
  border-radius: 50%;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.custom-modal .ant-modal-close .ant-modal-close-x {
  width: 20px;
  height: 20px;
  line-height: 15px;
}

.custom-modal .ant-modal-close .ant-modal-close-x svg {
  width: 10px;
  height: 10px;
}

.custom-modal-content {
  background-color: #fff;
  padding         : 2em;
  border-radius   : 0px 0px .5em .5em;
}


.custom-modal-content .custom-modal-content-title {
  display      : flex;
  margin-bottom: 1em;
}

.custom-modal-content .custom-modal-content-title .custom-modal-title {
  font-weight : bold;
  color       : #828282;
  margin-right: 1.5em;
  min-width   : 5.7em;
}

.custom-modal-content .custom-modal-content-title .custom-modal-description::before {
  content     : ':';
  margin-right: 0.5em;
  margin-left : -0.8em;
}

::-webkit-scrollbar {
  width : 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background   : var(--primary-button);
  border-radius: 4px;
}
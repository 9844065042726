.template-form {
  width: 100%;
  display: flex;
 flex-direction: row;
}
.template-form .form-wrapper .template-canvas{
  width: 100% !important;
  height: 100% !important;
  margin-top: 2rem;
  border:1px solid #000000;
}

.ml-2{
  margin-left: 1rem;
}
.ant-dropdown-trigger {
  background-color: transparent !important;
  border: transparent !important;
}
.template-form .element-panel {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* justify-content: space-between; */
}

.template-form .editor-wrapper .canvas-action{
  background-color: #f5f5f5;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.template-form .editor-wrapper .canvas-action .canvas-slider{
  width: 15%;

}

.flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.template-form .element-panel .element-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: space-between; */
}

.template-form .element-panel .element-list .element-item{
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
}
.template-form .element-panel .element-list .element-item.active{

  background-color: #B3EAE9;
}

.template-form .button-link {

  color:#c5c5c5;
  border: transparent;
}
.template-form .element-panel .element-action {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.5rem;
  /* justify-content: space-between; */
}
.template-form .element-panel .element-action .action-wrapper {
  display: flex;
  flex:1;
  justify-content: space-evenly;
}
.template-form .element-panel .element-action .button-action{
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: center;

  /* justify-content: space-between; */
}

.template-form .editor-wrapper {
  display: flex;
  flex: 5;
  flex-direction: column;
  /* justify-content: space-between; */
}
.template-form .editor-wrapper{
  display: flex;
  flex: 5;
  flex-direction: column;
  /* justify-content: space-between; */
}
.template-form .canvas-space {
  height: 100%;
  background-color: #c5c5c5;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.template-form .canvas-space .template-properties{
  display: flex;
  flex-direction: column;
  border-radius: 6px 6px 6px 6px;
  background-color: white;
  width: 35%;
  height: min-content;
}
.template-form .canvas-space .title-template{
  font-size: 1rem;
  font-weight: 700;
  color: #616161;
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 6px 6px 0px 0px;
}

.radio-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 600;
  padding-block: 0.8rem;
  padding-inline: 1rem;
}
div.radio{
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--color-bg-radio);
  margin-right: 0.5rem;
  border-radius: 15px;
  border: 1px solid var(--color-border-radio);
  display: flex;
  align-items: center;
  justify-content: center;
}


/* .radio-container > div.radio-title{
  margin-right: 1rem;
  padding-inline: 0.5rem;
  
} */
.media-form  .form-wrapper .date-container .radio-container>  div.radio.active{
  border-color: var(--primary-button);
}

.template-form .canvas-space .form{
  padding:0.5rem
}

/* Chrome, Safari, Edge, Opera */
.template-form input::-webkit-outer-spin-button,
.template-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.template-form input[type=number] {
  -moz-appearance: textfield;
}

.template-form .form-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.template-form .form-wrapper > div:first-child {
  width: 100%;
  margin-right: 1rem;
}

.template-form .form-wrapper > div:last-child {
  width: 100%;
  margin-left: 1rem;
}

.template-form .form-wrapper .form > .form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
  color: var(--color-text-black);
  width: 100%;
  font-weight: 600;
}

.template-form .form-wrapper .form {
  margin-bottom: 1rem !important;
}

.template-form .upload-image-preview {
  position: relative;
  width: 100%;
}

.template-form .upload-image-preview > img,
.template-form .upload-image-preview > video {
  width: 100%;
  border-radius: 10px;
  height: 140px;
  object-fit: contain;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}

.template-form .upload-image-preview > .btn-remove {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--color-bg-pink);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 0.25rem;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.template-form .form-wrapper .template-canvas{
 margin-top: 0 !important;
}

.template-form .upload-drag-and-drop {
  padding: 1.5rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--color-border);
  background-color: var(--color-input);
}

.ant-upload {
  width: 100%;
}

.template-form .upload-drag-and-drop > i {
  font-size: 1.5em;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-secondary);
}

.template-form .upload-drag-and-drop > p {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: var(--color-secondary);
}

.template-form .upload-drag-and-drop:hover,
.template-form .upload-drag-and-drop:hover > i,
.template-form .upload-drag-and-drop:hover > p,
.template-form .upload-drag-and-drop.active,
.template-form .upload-drag-and-drop.active > i,
.template-form .upload-drag-and-drop.active > p {
  border-color: var(--color-input-focus);
  /* color: var(--color-active); */
}


.template-form .upload-drag-and-drop > p.upload-title span.upload-highlight {
  color: var(--primary-button);
}

.template-form .upload-drag-and-drop > p.upload-subtitle {
  color: var(--color-text-gray-dark);
  font-weight: 400;
  font-size: .8rem;
}

.template-form .button-wrapper {
  margin-top: 2rem;
}




.template-form .button-wrapper .button-primary {
  width: 100%;
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  margin: 0.5rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.template-form .button-wrapper .button-primary:disabled {
  opacity: 0.5;
  cursor: disabled;
}

.template-form .button-secondary {
  margin-right: 1rem;
  background-color: #fff;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.template-form .form-group {
  display: flex;
  align-items: center;
}

.template-form .form input[type='color'] {
  background-color: rgb(243, 156, 18);
  border: none;
  width: 2rem;
  height: 2rem;
  padding: 0px;
  border-radius: 5px;
}

.template-form .input-group {
  display: flex;
  align-items: center;
}

.template-form .input-group > div:last-child {
  background-color: var(--color-stroke);
  padding: 0.3rem 1em;
}

.template-form .card-adsign .card-title > p {
  font-weight: normal;
}

.template-form .card-adsign-content .button-link {
  background-color: transparent;
}

.template-form .card-checkbox span {
  font-weight: normal;
}

.template-form .card-adsign-content .copy-popup {
  right: 0;
}

.template-form .form .form-split {
  display: flex;
  justify-content: space-between;
}




.template-form .form .form-split label.form-label {
  margin-bottom: 0.5rem;
  display: inline-block;
}

.template-form .form-group span {
  display: inline-block;
  margin: 0px 0.5rem;
}


.template-form input.ant-input, .template-form textarea.ant-input {
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
}

.template-form input.ant-input::placeholder,
.template-form textarea.ant-input::placeholder {
  color: var(--color-text-light);
}

.template-form input.ant-input:focus, .template-form textarea.ant-input:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--color-input-focus) !important;
}


.template-form .ant-picker {
  width: 100%;
  background-color: var(--color-input);
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
}

.template-form .ant-picker.ant-picker-focused {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.template-form .ant-select-selector {
  width: 100%;
  background-color: var(--color-input) !important;
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.15rem 1rem !important;
  height: auto !important;
}

.template-form .ant-select-open .ant-select-selector, .template-form .ant-select-focused .ant-select-selector {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.template-form .btn-primary {
  color: #fff;
  background-color: var(--primary-button);
  border-radius: 0.35rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: none;
  font-weight: 600;
  box-shadow: 0px 12px 12px rgb(83 215 245 / 10%);
  cursor: pointer;
}

.ant-picker-range .ant-picker-clear {
  right: 41px !important;
}